var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-rating',{attrs:{"background-color":"orange lighten-3","color":"orange","medium":"","readonly":""},model:{value:(_vm.product.rating),callback:function ($$v) {_vm.$set(_vm.product, "rating", $$v)},expression:"product.rating"}}),(_vm.authenticated)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Оставить отзыв ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Отзыв")])]),_c('v-card-text',[(_vm.product.can_create_review)?[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Rating","rules":"required|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return _c('v-rating',{attrs:{"background-color":"orange lighten-3","color":"orange","medium":"","error-messages":errors,"success":valid,"label":"Rating"},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Review","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return _c('v-textarea',{attrs:{"counter":255,"error-messages":errors,"label":"Review"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})}}],null,true)})],1)],1)],1)]}}],null,false,2177195313)})]:[_c('small',[_vm._v("* Мы не нашли эту книгу среди ваших покупок, отзыв может оставить только тот человек который купил книгу")])]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),(_vm.product.can_create_review)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }