<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-rating
          v-model="product.rating"
          background-color="orange lighten-3"
          color="orange"
          medium
          readonly
        ></v-rating>
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          v-if="authenticated"
        >
          Оставить отзыв
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Отзыв</span>
        </v-card-title>
        <v-card-text>
          <template v-if="product.can_create_review">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider name="Rating" rules="required|max:5">
                      <v-rating
                        v-model="rating"
                        slot-scope="{
                          errors,
                          valid
                        }"
                        background-color="orange lighten-3"
                        color="orange"
                        medium
                        :error-messages="errors"
                        :success="valid"
                        label="Rating"
                      ></v-rating>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider name="Review" rules="max:255">
                      <v-textarea
                        slot-scope="{
                          errors,
                          valid
                        }"
                        :counter="255"
                        v-model="text"
                        :error-messages="errors"
                        label="Review"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col>

                </v-row>
              </v-container>
            </ValidationObserver>
          </template>
          <template v-else>
            <small>* Мы не нашли эту книгу среди ваших покупок, отзыв может оставить только тот человек который купил
              книгу</small>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="product.can_create_review"
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
    import ApiProductReviews from '../../api/product-reviews'
    import {ValidationProvider, ValidationObserver, withValidation} from 'vee-validate';
    import {mapState} from "vuex";

    export default {
        props: ['product'],
        name: "RatingModal",
        data: () => ({
            dialog: false,
            rating: 5,
            text: ''
        }),
        computed: {
            ...mapState({
                'authenticated': state => state.auth.authenticated,
            }),
        },
        methods: {
            async save() {
                if (await this.$refs.observer.validate()) {
                    try {
                        await ApiProductReviews.store(this.product, {
                            rating: this.rating,
                            text: this.text
                        });

                        this.reset()
                        this.$store.dispatch('productReviews/fetchReviews', {product: this.product}, {root: true})
                        this.dialog = false
                    } catch (e) {
                        alert('Error')
                    }
                }
            },
            reset() {
                this.rating = 5
                this.text = ''
            }
        },
        components: {
            ValidationProvider, ValidationObserver
        }
    }
</script>

<style scoped>

</style>
