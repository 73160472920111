<template>
  <div>
    <vue-horizontal-list
      :items="products"
      :options="options"
      :style="$vuetify.breakpoint.smAndDown ? 'overflow:hidden' : ''"
    >

      <template v-slot:default="{ item }">
        <v-card
          class="mx-5 pa-0 elevation-0 transparent"
          width="155px"
          :height="$vuetify.breakpoint.mdAndUp ? '190px' : 'auto'"
          elevation="0"
          @click="$router.push({name: 'books.show', params: {slug: item.slug}})"
        >
          <!--          <product-card-slide :product="item" />-->
          <div class="image-container">
            <img :src="item.image_url"/>
          </div>

          <div class="cut-text">{{ item.name }}</div>

          <div class="price">R {{ item.price }}
            <!--              <v-icon small>mdi-currency-usd</v-icon>-->
          </div>


        </v-card>
      </template>
    </vue-horizontal-list>
  </div>
</template>

<script>
import VueHorizontalList from "vue-horizontal-list";

export default {
  name: "ProductsSmallSlider",
  components: {
    VueHorizontalList
  },
  props: ['products'],
  data() {
    return {
      items: [],
      options: {
        responsive: [
          {end: 576, size: 3},
          {start: 576, end: 768, size: 4},
          {start: 768, end: 992, size: 5},
          {size: 5},
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
p {
  margin-top: 6px;

  & .sm {
    font-size: smaller;
  }
}

.vue-horizontal-list {
  .vhl-navigation {
    .vhl-btn-left, .vhl-btn-right {
      background: #c1c1c1 !important;

      svg, path {
        fill: white;
      }
    }
  }
}

.cut-text {
  width: 100%;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;
  display: inline-block;
  font-size: .9em;
  font-weight: bolder;
  text-align: center;
  color: var(--color-blue);
}

@media (max-width: 400px) {
  .cut-text {
    display: block;
    text-overflow: unset;
    white-space: unset;
    //font-size: 3em;
  }
}

.price {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.image-container {
  border-radius: 3px;
  overflow: hidden;
  max-height: 123px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  //padding-top: 60%;
}

img {
  //object-fit: cover;
  max-height: 123px;
  max-width: 75px;
  //width: 100%;
  //
  object-fit: contain;
  //width: 90%;
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 5%;
  //right: 0;
}
</style>
