<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="5" lg="5">
        <v-carousel
          width="100%"
          delimiter-icon="mdi-minus"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            class="carousel-contain"
            v-for="(item,i) in [{src: variation.image_url || product.image_url}]"
            :key="i"
          >
            <v-card
              height="100%"
              color="#a5a7aa"
              class="d-flex justify-center align-center rounded-xl"
            >
              <v-btn
                :class="{favorite: isFavorite}"
                text
                @click="handleFavorite"
                style="position:absolute;top: 5px;right: 5px;"
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <img class="rounded" style="max-width: 270px" :src="item.src"/>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" sm="6" md="7" lg="7">
        <div class="pl-10">
          <h1 class="font-weight-regular mt-5">
            {{ variation.name || product.name }}
          </h1>
          <div class="ISBN" v-if="product.isbn">{{ product.isbn }}</div>
          <div class="description mt-10">
            <h3 class="pb-3">Description</h3>
            <div v-html=" variation.description || product.description"></div>
          </div>
          <div class="formats mt-5">
            <div class="d-flex ">
              <span class="formats_info" v-if="product.file_extension">
                {{ product.file_extension.toUpperCase() }}
              </span>
            </div>
          </div>
          <div class="my-sm-5 my-lg-15"></div>
<!--          <div class="pa-0">-->
<!--            <del>was R <span>{{ (parseFloat(product.price) + parseFloat(product.price) * .2).toFixed(2) }}</span>-->
<!--            </del>-->
<!--            (20% off)-->
<!--          </div>-->
          <v-row no-gutters justify="space-between">
            <div class="price">
              R
              <!-- <v-icon class="ml-n1 mb-1">mdi-currency-usd</v-icon> -->
              {{ variation.price || product.price }}
              <!-- price in South African Rands -->
            </div>
<!--            <div>-->
<!--              <v-icon>mdi-check-circle-outline</v-icon>-->
<!--              In stock-->
<!--            </div>-->
          </v-row>
          <div style="color: #a5a7aa">VAT included (where applicable)</div>
		 <v-row no-gutters>
		  <v-col v-if="!alreadyInCart" cols="12">
		   <state-indicate v-slot:default="{usePromise, isLoading}">
		   <v-btn :loading="isLoading"
				  x-large
				  width="100%"
				  color="primary"
				  class="mt-3 rounded-lg"
				  @click="usePromise(addToCart({product}))">
			add to cart
		   </v-btn>
		  </state-indicate></v-col>
		  <template v-else >


		   <v-col cols="8" sm="12" md="7">
			<v-btn :to="{name: 'cart'}" x-large
				   width="100%"
				   color="success"
				   class="mt-3 rounded-lg rounded-tr-0 rounded-br-0 ">
			 <div class="d-flex flex-column">
			  <strong>
			   In the shopping cart
			  </strong>
			  <span>Go to cart</span>
			 </div>
			</v-btn>
		   </v-col>
		   <v-col cols="4" sm="12" md="3">
			<state-indicate v-slot:default="{usePromise, isLoading}">
			 <v-btn
			   :loading="isLoading"
			   @click="usePromise(removeFromCart({product}))"
			   width="100%"
			   color="error"
			   x-large
			   class="mt-3 rounded-lg rounded-tl-0 rounded-bl-0"
			 >
			  <v-icon dark>
			   mdi-close
			  </v-icon>
			  Delete
			 </v-btn>
			</state-indicate>
		   </v-col>
		  </template>

		 </v-row>
        </div>

      </v-col>
    </v-row>
    <v-row class="pt-15">
      <v-col cols="12"><h2>You may also like</h2></v-col>
      <v-col cols="12" style="background-color: var(--color-pink)">
        <products-small-slider
          v-if="products && products.length > 0"
          :products="products"/>
      </v-col>
    </v-row>

    <v-row class="pt-15">
      <v-col cols="12"><h2>Explore more</h2></v-col>
      <v-col cols="6" md="4" lg="2"
             v-for="product in products"
             :key="product.id"
      >
        <!--        <v-card-->
        <!--          class="mx-5 px-2 py-0 my-0 transparent"-->
        <!--          max-width="200px"-->
        <!--          elevation="0"-->
        <!--        >-->
        <product-card-slide-small :product="product"/>
        <!--        </v-card>-->
      </v-col>
    </v-row>


    <!--<v-row>
            <div>
              <h4>Вариации</h4>
              <div class="d-flex">
                <div class="pa-2 pointer" :class="{'selected-variation': variation.id === item.id}" v-for="item in product.variations">
                  <v-img  @click="selectVariation(item)" :src="item.image_url" width="40px"></v-img>
                </div>
              </div>
            </div>
            <div class="mt-10">
        <v-btn :loading="addToCartProgress"
               :disabled="addToCartProgress"
               @click="handleAddToCart">
          <v-icon>mdi-cart</v-icon>
          Add to
        </v-btn>

      </div>

      <div>
        <rating-modal :product="product"></rating-modal>
      </div>-->
    <!--    <v-row v-if="false">
          <v-col cols="12">
            {{ product.description }}
          </v-col>
          <v-col cols="12">
            <v-card
              class="mx-auto my-12"
              v-for="review in reviews"
              :key="review.id"
            >
              <v-card-text>
                <v-row
                  align="center"
                  class="mx-0"
                >
                  <v-rating
                    :value="review.rating"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>
                </v-row>

                <div>{{ review.text }}</div>
              </v-card-text>

            </v-card>
          </v-col>

        </v-row>
        </v-row>-->

    <div class="my-sm-5 my-lg-15"></div>
  </v-container>
</template>

<script>

import Product from "../models/Product";
import {mapActions, mapState} from 'vuex';
import RatingModal from "../components/products/RatingModal";
import ProductsSmallSlider from "../components/products/ProductsSmallSlider";
import ProductCardSlideSmall from "../components/products/ProductCardSlideSmall";

export default {
  name: "Product",
  components: {
    RatingModal, ProductsSmallSlider, ProductCardSlideSmall
  },
  data: function () {
    return {
      product: {},
      products: [],
      variation: {},
      images: [],
      addToCartProgress: false,
      isFavorite: false,
      meta: {},
      page: 1,
      formatsButton: false,
    }
  },
  mounted() {
    this.fetchProduct();
    this.fetchProducts()
  },
  computed: {
    ...mapState({
      favorites: state => {
        return state.favorites.items;
      },
      reviews: state => {
        return state.productReviews.items;
      },
    }),
	alreadyInCart() {
      return !!this.$store.state.cart.items.find(i => i.id === this.product.id);
	}
  },
  watch: {
    favorites(newFavorites, oldFavorites) {
      this.defineIsInFavorites()
    }
  },
  methods: {
    ...mapActions({
	  removeFromCart: 'cart/removeFromCart',
      addToCart: 'cart/addToCart'
  }),
    fetchProducts() {
      // todo For get model method, then and catch method for handling status request, or await promise, and handle with ApiResponse 'successfully method'
      return Product
        .page(this.page)
        .limit(12)
        .get()
        .then(ApiResponse => {
          this.products = ApiResponse.getData();
          this.meta = ApiResponse.getMeta();
        });
    },
    async fetchProduct() {
      this.product = await Product.$find(this.$route.params.slug)
      this.defineIsInFavorites()
      this.fetchReviews()
    },
    async fetchReviews() {
      await this.$store.dispatch('productReviews/fetchReviews', {product: this.product}, {root: true})
    },
    handleAddToCart() {
      return this.$store.dispatch('cart/addToCart', {product: this.product}, {root: true});
    },
    async handleFavorite() {
      this.isFavorite
        ? await this.$store.dispatch('favorites/removeFromFavorites', {product: this.product}, {root: true})
        : await this.$store.dispatch('favorites/addToFavorites', {product: this.product}, {root: true})
    },

    defineIsInFavorites() {
      for (let i = 0; i < this.favorites.length; i++) {
        if (this.favorites[i].id === this.product.id) {
          this.isFavorite = true
          return
        }
      }
      this.isFavorite = false
    },
    selectVariation(variation) {
      if (this.variation.id === variation.id) {
        this.variation = {}
        return
      }
      this.variation = variation
    },
  },

}
</script>

<style lang="scss" scoped>
@import "../assets/scss/components/product/_product.scss";

.ISBN {
  &:before {
    content: "ISBN: ";
    font-weight: bold;
  }
}

.description {
  font-size: larger;
}
.formats_info{
  width: 4.3rem;
  padding: 1rem .8rem;
  background: #0d346d;
  color: #fff;
  border: 1px solid #0d346d;
  border-radius: 8px;
  text-align: center;
  outline: none;
}


.price {
  font-size: 2em;
  font-weight: bold;
}
</style>
