<template>
  <v-card
    class="mx-5 pa-0 elevation-0"
    max-width="200px"
    max-height="440px"
    elevation="0"
    @click="$router.push({name: 'books.show', params: {slug: product.slug}})"
  >
    <v-img
      :src="product.image_url"
      @click="handleCardClick"
      max-width="115px"
      max-height="440px"
      class="mx-auto"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-title @click="handleCardClick" class="cut-text pa-0">{{ product.name }}</v-card-title>
    <v-card-text class="pa-0 old_price"><del>was R <span>{{ (parseFloat(product.price) + parseFloat(product.price) * .2).toFixed(2) }}</span></del> (20% off)
    </v-card-text>
    <v-card-subtitle class="pa-0 price">
      R {{ product.price }}

    </v-card-subtitle>
    <div class="mt-1">
      <v-chip
        color="#ddcecd"
        label
        small
      >
        <v-icon left small>
          mdi-medal-outline
        </v-icon>
        bestseller
      </v-chip>
    </div>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ProductCardSlideSmall",
  props: ['product'],
  data() {
    return {
      isFavorite: false
    }
  },
  computed: {
    ...mapState({
      favorites: state => {
        return state.favorites.items;
      },
    }),
  },
  watch: {
    favorites(newFavorites, oldFavorites) {
      this.defineIsInFavorites()
    }
  },
  mounted() {
    this.defineIsInFavorites()
  },
  methods: {
    handleCardClick() {
      this.$router.push({name: 'books.show', params: {slug: this.product.slug}})
    },
    async handleFavorite() {
      this.isFavorite
        ? await this.$store.dispatch('favorites/removeFromFavorites', {product: this.product}, {root: true})
        : await this.$store.dispatch('favorites/addToFavorites', {product: this.product}, {root: true})
    },
    defineIsInFavorites() {
      for (let i = 0; i < this.favorites.length; i++) {
        if (this.favorites[i].id === this.product.id) {
          this.isFavorite = true
          return
        }
      }
      this.isFavorite = false
    }
  }
}
</script>

<style scoped>
.price {
  font-size: 1.2em;
  font-weight: bold;
  color: black !important;
}
.old_price{
  font-size: .7em;
}
.cut-text {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
}

.card {
  text-align: center;
}

.w-100 {
  width: 100%;
}
</style>
